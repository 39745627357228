import { useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// routes
import { usePathname } from 'src/routes/hooks';
// components
import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';
//
import { NavProps } from '../types';
import NavList from './nav-list';

// ----------------------------------------------------------------------

export default function NavMobile({ offsetTop, data }: NavProps) {
    const pathname = usePathname();

    const nav = useBoolean();

    useEffect(() => {
        if (nav.value) {
            nav.onFalse();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    return (
        <>
            {/* <IconButton
        onClick={nav.onTrue}
        sx={{
          ml: 1,
          ...(offsetTop && {
            color: 'text.primary',
          }),
        }}
      > */}
            <Box component="img" src="/assets/hamboger.png" onClick={nav.onTrue} />
            {/* <SvgColor src="/assets/hamboger.png" /> */}
            {/* </IconButton> */}

            <Drawer
                open={nav.value}
                onClose={nav.onFalse}
                PaperProps={{
                    sx: {
                        pb: 5,
                        width: 260
                    }
                }}
                sx={{ '& .MuiPaper-root': { background: '#010005 !important' } }}
            >
                <Scrollbar>
                    <Logo sx={{ mx: 2.5, my: 3 }} />

                    <List component="nav" disablePadding>
                        {data.map((link) => (
                            <NavList key={link.title} item={link} />
                        ))}
                    </List>
                </Scrollbar>
            </Drawer>
        </>
    );
}
