// @mui
import Box from '@mui/material/Box';
// routes
import { usePathname } from 'src/routes/hooks';
//
import Header from './header';

// ----------------------------------------------------------------------

type Props = {
    children: React.ReactNode;
};

export default function MainLayout({ children }: Props) {
    const pathname = usePathname();

    const isHome = pathname === '/';

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
            <Header />

            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    ...(!isHome && {
                        pt: { xs: 8, md: 10 }
                    })
                }}
                mt="-113px"
            >
                {children}
            </Box>

            {/* <Footer /> */}
        </Box>
    );
}
