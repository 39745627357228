// routes
import { paths } from 'src/routes/paths';
// config
// components
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

export const navConfig = [
    {
        title: 'Home',
        icon: <Iconify icon="ion:home" />,
        path: '/'
    },
    {
        title: 'About Us',
        icon: <Iconify icon="solar:atom-bold-duotone" />,
        path: paths.about
    },
    {
        title: 'Consulting Services',
        icon: <Iconify icon="grommet-icons:services" />,
        path: paths.consulting
    },
    {
        title: 'Data Products',
        icon: <Iconify icon="icon-park-outline:data-user" />,
        path: paths.product.root
    },
    {
        title: 'Why Choose Us',
        icon: <Iconify icon="gis:globe-users" />,
        path: paths.chooseus
    },
    {
        title: 'Contact Us',
        icon: <Iconify icon="mdi:email-seal-outline" />,
        path: paths.contact
    }
];
