// @mui
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// theme
import { bgBlur } from 'src/theme/css';
// components
import { useSettingsContext } from 'src/components/settings';
import Logo from 'src/components/logo';
//
import { HEADER } from '../config-layout';
import { navConfig } from './config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
//
import { HeaderShadow } from '../_common';

// ----------------------------------------------------------------------

export default function Header() {
    const theme = useTheme();

    const mdUp = useResponsive('up', 'md');

    const settings = useSettingsContext();

    const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

    return (
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar
                disableGutters
                sx={{
                    mt: { xs: 1, md: 4 },
                    height: {
                        xs: HEADER.H_MOBILE,
                        md: HEADER.H_DESKTOP
                    },
                    transition: theme.transitions.create(['height'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.shorter
                    }),
                    ...(offsetTop && {
                        ...bgBlur({
                            color: theme.palette.background.default
                        }),
                        height: {
                            md: HEADER.H_DESKTOP_OFFSET
                        }
                    })
                }}
            >
                <Container
                    sx={{ height: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                    maxWidth={settings.themeStretch ? false : 'xl'}
                >
                    {!mdUp && <NavMobile offsetTop={offsetTop} data={navConfig} />}

                    <Logo ml={!mdUp ? '-24px' : 0} />

                    {!mdUp && <Typography> &nbsp;</Typography>}

                    {mdUp && <NavDesktop offsetTop={offsetTop} data={navConfig} />}
                    <Box>&nbsp;</Box>
                    {/* {mdUp && (
            <Stack alignItems="center" direction={{ xs: 'row', md: 'row-reverse' }}>
              <Button
                variant="contained"
                rel="noopener"
                href="#"
                sx={{
                  bgcolor: 'rgba(13, 73, 204, 0.4)',
                  border: '1px solid #0D49CC',
                  color: '#FFFFFF',
                  fontWeight: 700,
                }}
              >
                GET STARTED
              </Button>
            </Stack>
          )} */}
                </Container>
            </Toolbar>

            {offsetTop && <HeaderShadow />}
        </AppBar>
    );
}
